import { Routes } from '@angular/router';
import { PublicGuard } from '@core/middlewares/public.guard';

export const routes: Routes = [
  { path: '', loadComponent: () =>  import('@modules/home/home.component').then(m => m.HomeComponent), canActivate: [PublicGuard]},
  { path: '', redirectTo: '/reports', pathMatch: 'full', },
  { path: '404', loadComponent: () =>  import('@modules/notfound/notfound.component').then(m => m.NotFoundComponent), canActivate: [PublicGuard]},
  { path: 'airports', loadComponent: () =>  import('@modules/airports/airports.component').then(m => m.AirportsComponent), canActivate: [PublicGuard]},
  { path: 'surface_status/:id', loadComponent: () =>  import('@modules/surfacestatus/surfacestatus.component').then(m => m.SurfaceStatusComponent), canActivate: [PublicGuard]},
  { path: 'reports/:id', loadComponent: () =>  import('@modules/reports/reports.component').then(m => m.ReportsComponent), canActivate: [PublicGuard]},
  { path: '**', redirectTo: '/404' },
];
