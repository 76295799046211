import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppData {
    user: any;
    currentUser$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    mode = 'range';
    memoryUsage: any = '';

    baseGridData: any = {};
    cache: any = {
        'data': {},
        'exData': {},
        'map': {},
        'templates': {}
    };

    countOperations = 0;
    reportId = '';
    surfacestatusStart = '';
    surfacestatusEnd = '';
    airportId = '';
    airportData: any;

    startTimeAnimated: any;
    startTimeAnimatedChanged$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    notification: any = {
        enable: false,
        datas: [],
        date: new Date(),
    };

    timeConfig: any = {
        play: true,
        speed: 10,
        isFuture: false,
    }

    readonly #isPrint = new BehaviorSubject<boolean>(false);
    readonly isPrint$ = this.#isPrint
        .asObservable();
            
    changesIsPrint(val: boolean): void {
        this.#isPrint.next(val);
    }

    countReleaverMap = 0;

    readonly #isLoadingMap = new BehaviorSubject<boolean>(true);
    readonly isLoadingMap$ = this.#isLoadingMap
        .asObservable();

    readonly #isLoadingMapReliever = new BehaviorSubject<boolean>(true);
    readonly isLoadingMapReliever$ = this.#isLoadingMapReliever
        .asObservable();

    readonly #isLoadingMapNoise = new BehaviorSubject<boolean>(true);
    readonly isLoadingMapNoise$ = this.#isLoadingMapNoise
        .asObservable();
            
    changesIsLoadingMap(val: boolean): void {
        this.#isLoadingMap.next(val);
    }

    changesIsLoadingMapReliever(val: boolean): void {
        this.#isLoadingMapReliever.next(val);
    }

    changesIsLoadingMapNoise(val: boolean): void {
        this.#isLoadingMapNoise.next(val);
    }

    readonly #isLoadingAPI = new BehaviorSubject<boolean>(false);
    readonly isLoadingAPI$ = this.#isLoadingAPI
        .asObservable();

    changesIsLoadingAPI(val: boolean): void {
        this.#isLoadingAPI.next(val);
    }

    readonly #isLoading = new BehaviorSubject<boolean>(false);
    readonly isLoading$ = this.#isLoading
        .asObservable();

    changesIsLoading(val: boolean): void {
        this.#isLoading.next(val);
    }
    
    readonly #layout = new BehaviorSubject<string>('default');
    readonly layout$ = this.#layout
        .asObservable();

    changesLayout(val: string): void {
        this.#layout.next(val);
    }

    readonly #layoutBg = new BehaviorSubject<string>('bg-white');
    readonly layoutBg$ = this.#layoutBg
        .asObservable();

    changesLayoutBG(val: string): void {
        this.#layoutBg.next(val);
    }
    
    type: "MSP" | "RELIEVER" | "" = "";

    mapView:any = {};
    mapsReliever: any = {
        'STP-Map': {
        'initialized': false,
        'activeLayers': [],
        'activeLegends': []
        },
        'FCM-Map': {
        'initialized': false,
        'activeLayers': [],
        'activeLegends': []
        },
        'ANE-Map': {
        'initialized': false,
        'activeLayers': [],
        'activeLegends': []
        },
        'MIC-Map': {
        'initialized': false,
        'activeLayers': [],
        'activeLegends': []
        },
        'LVN-Map': {
        'initialized': false,
        'activeLayers': [],
        'activeLegends': []
        },
        '21D-Map': {
        'initialized': false,
        'activeLayers': [],
        'activeLegends': []
        }
    };
    
    constructor() {
    }
}
