import { Injectable } from '@angular/core';
import { State, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, NgxsOnChanges, Action } from '@ngxs/store';
import { ReportAction } from './report.action';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Report } from './report.model';
import { clone } from '@shareds/utils/data.utils';

export interface ReportStateModel {
    reports: Report.Entity[];
    report: Report.Entity | null;
    loaded: boolean;
}

@State<ReportStateModel>({
    name: 'report',
    defaults: {
        reports: [],
        report: null,
        loaded: false,
    },
})
@Injectable()
export class ReportState implements NgxsOnInit, NgxsOnChanges {
    @Selector()
    static getReports(state: ReportStateModel) {
        return state.reports;
    }

    @Selector()
    static getReport(state: ReportStateModel) {
        return state.report;
    }

    @Selector()
    static hasLoaded(state: ReportStateModel) {
        return state.loaded;
    }

    constructor() {}

    ngxsOnInit(ctx: StateContext<ReportStateModel>): void {
        console.log('ngxsOnInit');
    }

    ngxsOnChanges(change: NgxsSimpleChange) {
        console.log('prev state', change?.previousValue);
        console.log('next state', change?.currentValue);
    }

    @Action(ReportAction.SetReport)
    async setReport({ getState, patchState }: StateContext<ReportStateModel>, { report }: ReportAction.SetReport) {
        const state = getState();
        patchState({
            report: report,
            loaded: true,
        });
    }

    @Action(ReportAction.ClearReport)
    async clearReport({ getState, patchState }: StateContext<ReportStateModel>, { }: ReportAction.ClearReport) {
        const state = getState();
        patchState({
            report: null,
            loaded: false,
        });
    }

    @Action(ReportAction.SetAll)
    async setAll({ getState, patchState }: StateContext<ReportStateModel>, { reports }: ReportAction.SetAll) {
        const state = getState();
        patchState({
            reports: reports,
            loaded: true,
        });
    }

    @Action(ReportAction.UpsertAll)
    async upsertAll({ getState, patchState }: StateContext<ReportStateModel>, { reports }: ReportAction.UpsertAll) {
        const state = getState();
        const itemExists = clone(state.reports) || [];
        reports?.forEach((i, index) => {
            const findIndex = itemExists.findIndex((e) => e.id === i.id);
            if (findIndex !== -1) {
                // itemExists[findIndex] = i;
            } else {
                itemExists.push(i);
            }
        });

        patchState({
            reports: itemExists,
            loaded: true,
        });
    }

    @Action(ReportAction.ClearAll)
    async clearAll({ getState, patchState }: StateContext<ReportStateModel>, { }: ReportAction.ClearAll) {
        const state = getState();
        patchState({
            reports: [],
            loaded: false,
        });
    }

    @Action(ReportAction.Create)
    async create({ getState, patchState, setState }: StateContext<ReportStateModel>, { report }: ReportAction.Create) {
        console.log("create", report);
        const state = getState();
        setState(
            patch({
                reports: insertItem(report),
                loaded: true,
            }),
        );
    }

    @Action(ReportAction.Update)
    async update({ getState, patchState, setState }: StateContext<ReportStateModel>, { report }: ReportAction.Update) {
        console.log("update", report);
        const state = getState();
        setState(
            patch({
                reports: updateItem(item => item.id === report.id, patch(report)),
                loaded: true,
            }),
        );
    }

    @Action(ReportAction.Delete)
    async delete({ getState, patchState, setState }: StateContext<ReportStateModel>, { report }: ReportAction.Delete) {
        console.log("delete", report);
        const state = getState();
        setState(
            patch({
                reports: removeItem<Report.Entity>((item) => item.id === report.id),
                loaded: true,
            }),
        );
    }
}
