import { Injectable } from '@angular/core';
import { State, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, NgxsOnChanges, Action } from '@ngxs/store';
import { OperationAction } from './operations.action';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Operation } from './operations.model';
import { clone } from '@shareds/utils/data.utils';

export interface OperationStateModel {
    operations: Operation.Entity[];
    loaded: boolean;
}

@State<OperationStateModel>({
    name: 'operation',
    defaults: {
        operations: [],
        loaded: false,
    },
})
@Injectable()
export class OperationState implements NgxsOnInit, NgxsOnChanges {
    @Selector()
    static getOperations(state: OperationStateModel) {
        return state.operations;
    }

    @Selector()
    static hasLoaded(state: OperationStateModel) {
        return state.loaded;
    }

    constructor() {}

    ngxsOnInit(ctx: StateContext<OperationStateModel>): void {
        console.log('ngxsOnInit');
    }

    ngxsOnChanges(change: NgxsSimpleChange) {
        console.log('prev state', change?.previousValue);
        console.log('next state', change?.currentValue);
    }

    @Action(OperationAction.SetAll)
    async setAll({ getState, patchState }: StateContext<OperationStateModel>, { operations }: OperationAction.SetAll) {
        const state = getState();
        patchState({
            operations: operations,
            loaded: true,
        });
    }

    @Action(OperationAction.UpsertAll)
    async upsertAll({ getState, patchState }: StateContext<OperationStateModel>, { operations }: OperationAction.UpsertAll) {
        const state = getState();
        const itemExists = clone(state.operations) || [];
        operations?.forEach((i, index) => {
            const findIndex = itemExists.findIndex((e) => e.id === i.id);
            if (findIndex !== -1) {
                // itemExists[findIndex] = i;
            } else {
                itemExists.push(i);
            }
        });

        patchState({
            operations: itemExists,
            loaded: true,
        });
    }

    @Action(OperationAction.ClearAll)
    async clearAll({ getState, patchState }: StateContext<OperationStateModel>, { }: OperationAction.ClearAll) {
        const state = getState();
        patchState({
            operations: [],
            loaded: false,
        });
    }

    @Action(OperationAction.Create)
    async create({ getState, patchState, setState }: StateContext<OperationStateModel>, { operation }: OperationAction.Create) {
        console.log("create", operation);
        const state = getState();
        setState(
            patch({
                operations: insertItem(operation),
                loaded: true,
            }),
        );
    }

    @Action(OperationAction.Update)
    async update({ getState, patchState, setState }: StateContext<OperationStateModel>, { operation }: OperationAction.Update) {
        console.log("update", operation);
        const state = getState();
        setState(
            patch({
                operations: updateItem(item => item.id === operation.id, patch(operation)),
                loaded: true,
            }),
        );
    }

    @Action(OperationAction.Delete)
    async delete({ getState, patchState, setState }: StateContext<OperationStateModel>, { operation }: OperationAction.Delete) {
        console.log("delete", operation);
        const state = getState();
        setState(
            patch({
                operations: removeItem<Operation.Entity>((item) => item.id === operation.id),
                loaded: true,
            }),
        );
    }
}
