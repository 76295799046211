import { Operation } from "./operations.model";

export namespace OperationAction {
    export class SetAll {
        static readonly type = '[Operation] Set All';
        constructor(public operations: Operation.Entity[]) {}
    }
    export class UpsertAll {
        static readonly type = '[Operation] Upsert All';
        constructor(public operations: Operation.Entity[]) {}
    }
    export class ClearAll {
        static readonly type = '[Operation] Clear All';
        constructor() {}
    }
    export class Create {
        static readonly type = '[Operation] Create';
        constructor(public operation: Operation.Entity) {}
    }
    export class Update {
        static readonly type = '[Operation] Update';
        constructor(public operation: Operation.Entity) {}
    }
    export class Delete {
        static readonly type = '[Operation] Delete';
        constructor(public operation: Operation.Entity) {}
    }
}
