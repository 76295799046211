export function clone(items: any): any[] {
    if (!items) {
        return items;
    }
    const newItems = JSON.parse(JSON.stringify(items));
    return newItems;
}

export function monthText(month: number) {
    const toText = ["","JANUARY", "FEBURARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];
    const integer = month;
    if (integer > 0 && integer < 13) {
      return toText[integer];
    } else {
      return "Invalid Selection";
    }
};
export function timeFormat(timeInSeconds: any) {
  let htmlOutput = '';
  const output = [];
  const time = [0, 0, 0];
  time[0] = Math.floor(timeInSeconds / 3600);
  time[1] = Math.floor((timeInSeconds - (time[0] * 3600)) / 60);
  time[2] = Math.floor(timeInSeconds - (time[0] * 3600) - (time[1] * 60));
  if (time[0] >= 10) {
    output.push('' + time[0] + ':');
  } else if (time[0] < 10) {
    output.push('0' + time[0] + ':');
  } else if (time[0] = 0) {
    output.push('00:');
  }
  if (time[1] >= 10) {
    output.push('' + time[1] + ':');
  } else if (time[1] < 10) {
    output.push('0' + time[1] + ':');
  } else if (time[1] = 0) {
    output.push('00:');
  }
  if (time[2] >= 10) {
    output.push('' + time[2]);
  } else if (time[2] < 10) {
    output.push('0' + time[2]);
  } else if (time[2] = 0) {
    output.push('00');
  }
  output.forEach((value) => {
    htmlOutput += '' + value;
  });
  return htmlOutput;
};

export function defineClosedFunc(array: any[], startD: string, endD: string): any[] {
  const start = new Date(startD)?.getTime()
  const end = new Date(endD)?.getTime();
  console.log("defineClosedFunc", start);
  const surfaceStatusClosed: any[] = array?.filter(((i: any) => {
    i.surfacesClosed = i?.surfaces?.filter((surface: any) => {
      if (surface?.stime && surface?.effective_etime) {
        const s = new Date(surface?.stime)?.getTime();
        const e = new Date(surface?.effective_etime)?.getTime();
        // if (start?.getTime() <= e?.getTime() && end?.getTime() >= s?.getTime() && end?.getTime() <= e?.getTime())
        // return true;
        // if (s <= start && e >= start || s >= start && e <= end || s >= start && e >= end) return true;
        if (s >= start && s <= end) return true;
        if (s >= start && e <= end) return true;

      return start >= s && end <= e;
      }
      if (surface?.stime && !surface?.effective_etime) {
        const s = new Date(surface?.stime);
        const end = new Date(endD);
        if (end >= s) return true;
      }
      return false;
    })
    if (i?.surfacesClosed?.length) return true;
    return false;
  }))
  return surfaceStatusClosed;
}

export function secondsToH(seconds: number) {
  const s = Math.round(seconds);
  const hours = Math.floor(s / 3600);
  return +hours;
}
export function secondsToHHMM(seconds: number) {
  const s = Math.round(seconds);
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  // const secs = s % 60;

  const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      // secs.toString().padStart(2, '0')
  ].join(':');

  return formattedTime;
}
export function secondsToHHMMSS(seconds: number) {
  const s = Math.round(seconds);
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  const secs = s % 60;

  const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
  ].join(':');

  return formattedTime;
}

export function convertToAmPmFormat(time: string) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12; // Convert 0 -> 12 for AM
  return `${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${period}`;
}
