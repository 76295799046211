import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';

import { NgxsModule } from '@ngxs/store';
import { environment } from '@environments/environment';
import { StoragePlugin } from './plugin/storage.plugin';
import { ReportState } from '@modules/reports/[data]/report.state';
import { GeojsonState } from '@modules/geojson/[data]/geojson.state';
import { OperationState } from '@modules/operations/[data]/operations.state';
import { SurfaceStatusState } from '@modules/surfacestatus/[data]/surfacestatus.state';

const STATES: any = [ReportState, GeojsonState, OperationState, SurfaceStatusState];
const STORAGE_STATES: any = [ReportState, SurfaceStatusState];

@NgModule({
    imports: [
        NgxsModule.forRoot([...STATES], {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot({
            keys: [...STORAGE_STATES],
        }),
    ],
    exports: [],
})
export class StateModule {
    constructor() {}

    static forRoot(): ModuleWithProviders<StateModule> {
        return {
            ngModule: StateModule,
            providers: [{
                provide: STORAGE_ENGINE,
                useClass: StoragePlugin,
            }],
        };
    }
}
