import { Report } from "./report.model";

export namespace ReportAction {
    export class SetReport {
        static readonly type = '[Report] Set Report';
        constructor(public report: Report.Entity) {}
    }
    export class ClearReport {
        static readonly type = '[Report] Clear Report';
        constructor() {}
    }
    export class SetAll {
        static readonly type = '[Report] Set All';
        constructor(public reports: Report.Entity[]) {}
    }
    export class UpsertAll {
        static readonly type = '[Report] Upsert All';
        constructor(public reports: Report.Entity[]) {}
    }
    export class ClearAll {
        static readonly type = '[Report] Clear All';
        constructor() {}
    }
    export class Create {
        static readonly type = '[Report] Create';
        constructor(public report: Report.Entity) {}
    }
    export class Update {
        static readonly type = '[Report] Update';
        constructor(public report: Report.Entity) {}
    }
    export class Delete {
        static readonly type = '[Report] Delete';
        constructor(public report: Report.Entity) {}
    }
}
