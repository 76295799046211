import { SurfaceStatus } from "./surfacestatus.model";

export namespace SurfaceStatusAction {
    export class SetSurfaceStatusTopojson {
        static readonly type = '[SurfaceStatus] Set SurfaceStatus Topojson';
        constructor(public topojson: SurfaceStatus.Entity) {}
    }
    export class SetSurfaceStatus {
        static readonly type = '[SurfaceStatus] Set SurfaceStatus';
        constructor(public surface: SurfaceStatus.Entity) {}
    }
    export class ClearSurfaceStatus {
        static readonly type = '[SurfaceStatus] Clear SurfaceStatus';
        constructor() {}
    }
    export class SetAll {
        static readonly type = '[SurfaceStatus] Set All';
        constructor(public surfaces: SurfaceStatus.Entity[]) {}
    }
    export class UpsertAll {
        static readonly type = '[SurfaceStatus] Upsert All';
        constructor(public surfaces: SurfaceStatus.Entity[]) {}
    }
    export class ClearAll {
        static readonly type = '[SurfaceStatus] Clear All';
        constructor() {}
    }
    export class Create {
        static readonly type = '[SurfaceStatus] Create';
        constructor(public surface: SurfaceStatus.Entity) {}
    }
    export class Update {
        static readonly type = '[SurfaceStatus] Update';
        constructor(public surface: SurfaceStatus.Entity) {}
    }
    export class Delete {
        static readonly type = '[SurfaceStatus] Delete';
        constructor(public surface: SurfaceStatus.Entity) {}
    }
}
