import { Injectable } from '@angular/core';
import { State, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, NgxsOnChanges, Action } from '@ngxs/store';
import { SurfaceStatusAction } from './surfacestatus.action';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { SurfaceStatus } from './surfacestatus.model';
import { clone } from '@shareds/utils/data.utils';

export interface SurfaceStatusStateModel {
    surfaces: SurfaceStatus.Entity[];
    surface: SurfaceStatus.Entity | null;
    loaded: boolean;
    topojson: any;
}

@State<SurfaceStatusStateModel>({
    name: 'surface',
    defaults: {
        surfaces: [],
        surface: null,
        topojson: null,
        loaded: false,
    },
})
@Injectable()
export class SurfaceStatusState implements NgxsOnInit, NgxsOnChanges {
    @Selector()
    static getSurfaceStatuses(state: SurfaceStatusStateModel) {
        return state.surfaces;
    }

    @Selector()
    static getSurfaceStatusTopojson(state: SurfaceStatusStateModel) {
        return state.topojson;
    }

    @Selector()
    static getSurfaceStatus(state: SurfaceStatusStateModel) {
        return state.surface;
    }

    @Selector()
    static hasLoaded(state: SurfaceStatusStateModel) {
        return state.loaded;
    }

    constructor() {}

    ngxsOnInit(ctx: StateContext<SurfaceStatusStateModel>): void {
        console.log('ngxsOnInit');
    }

    ngxsOnChanges(change: NgxsSimpleChange) {
        console.log('prev state', change?.previousValue);
        console.log('next state', change?.currentValue);
    }

    @Action(SurfaceStatusAction.SetSurfaceStatus)
    async setSurfaceStatus({ getState, patchState }: StateContext<SurfaceStatusStateModel>, { surface }: SurfaceStatusAction.SetSurfaceStatus) {
        const state = getState();
        patchState({
            surface: surface,
            loaded: true,
        });
    }

    @Action(SurfaceStatusAction.ClearSurfaceStatus)
    async clearSurfaceStatus({ getState, patchState }: StateContext<SurfaceStatusStateModel>, { }: SurfaceStatusAction.ClearSurfaceStatus) {
        const state = getState();
        patchState({
            surface: null,
            loaded: false,
        });
    }

    @Action(SurfaceStatusAction.SetAll)
    async setAll({ getState, patchState }: StateContext<SurfaceStatusStateModel>, { surfaces }: SurfaceStatusAction.SetAll) {
        const state = getState();
        patchState({
            surfaces: surfaces,
            loaded: true,
        });
    }

    @Action(SurfaceStatusAction.UpsertAll)
    async upsertAll({ getState, patchState }: StateContext<SurfaceStatusStateModel>, { surfaces }: SurfaceStatusAction.UpsertAll) {
        const state = getState();
        const itemExists = clone(state.surfaces) || [];
        surfaces?.forEach((i, index) => {
            const findIndex = itemExists.findIndex((e) => e.id === i.id);
            if (findIndex !== -1) {
                // itemExists[findIndex] = i;
            } else {
                itemExists.push(i);
            }
        });

        patchState({
            surfaces: itemExists,
            loaded: true,
        });
    }

    @Action(SurfaceStatusAction.ClearAll)
    async clearAll({ getState, patchState }: StateContext<SurfaceStatusStateModel>, { }: SurfaceStatusAction.ClearAll) {
        const state = getState();
        patchState({
            surfaces: [],
            loaded: false,
        });
    }

    @Action(SurfaceStatusAction.Create)
    async create({ getState, patchState, setState }: StateContext<SurfaceStatusStateModel>, { surface }: SurfaceStatusAction.Create) {
        console.log("create", surface);
        const state = getState();
        setState(
            patch({
                surfaces: insertItem(surface),
                loaded: true,
            }),
        );
    }

    @Action(SurfaceStatusAction.Update)
    async update({ getState, patchState, setState }: StateContext<SurfaceStatusStateModel>, { surface }: SurfaceStatusAction.Update) {
        console.log("update", surface);
        const state = getState();
        setState(
            patch({
                surfaces: updateItem(item => item.id === surface.id, patch(surface)),
                loaded: true,
            }),
        );
    }

    @Action(SurfaceStatusAction.Delete)
    async delete({ getState, patchState, setState }: StateContext<SurfaceStatusStateModel>, { surface }: SurfaceStatusAction.Delete) {
        console.log("delete", surface);
        const state = getState();
        setState(
            patch({
                surfaces: removeItem<SurfaceStatus.Entity>((item) => item.id === surface.id),
                loaded: true,
            }),
        );
    }
}
