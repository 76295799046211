export const environment = {
  production: false,
  api_server: 'qa-api.macnoms.com',
  key: "macnoms",
  useDummy: false,
  useDummySurface: false,
  map: {
    config: {
      token: 'pk.eyJ1IjoibXNwbWFjIiwiYSI6ImNqNGZsc3U1ZjE5bmkzM3BzMGFoZHh1dnEifQ.ZUId1x7cwzFK6elr4oHZQw',
      location: [-93.2, 44.85],
      basemap: 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v10/tiles/256/{z}/{x}/{y}' + '?access_token='
    },
  }
};
