import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { SplashscreenService } from './@core/services/splashscreen.service';
import { AppData } from '@appdata';
import { ConvertBytesPipe } from '@shareds/pipes/convert-bytes.pipe';
import { LayoutComponent } from '@shareds/layouts/layout.component';

declare var window: any;
import * as bootstrap from 'bootstrap';
import { LoaderComponent } from '@shareds/components/loader/loader.component';
import { ToastsContainer } from '@core/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ConvertBytesPipe, LayoutComponent, LoaderComponent, ToastsContainer],
  providers: [ConvertBytesPipe],
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  constructor(
    private splashscreenService: SplashscreenService,
    public appData: AppData,
    private convertBytesPipe: ConvertBytesPipe,
    private router: Router,
  ) {
    setInterval(() => {
      const memory = window?.performance["memory"];
      this.appData.memoryUsage = this.convertBytesPipe.transform(memory?.usedJSHeapSize, 'mb');
      console.log("Memory Usage:", this.appData.memoryUsage);
    }, 15000);
  }

  ngOnInit(): void {
  }

  get isFromReport() {
    if (this.router.url?.includes("reports")) {
      return true;
    }
    return false;
  }
}
