import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

type ReturnType =
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree;

@Injectable({ providedIn: 'root' })
export class PublicGuard implements CanActivate, CanActivateChild {
    constructor(protected router: Router) {}

    canActivate(): ReturnType {
        return true;
    }

    canActivateChild(): ReturnType {
        return this.canActivate();
    }

    canLoad(): ReturnType {
        return this.canActivate();
    }
}
