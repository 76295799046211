import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertBytes',
    standalone: true
})
export class ConvertBytesPipe implements PipeTransform {
    transform(bytes: number, unit: string): string | null {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return null;

        let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let selectedUnit = unit.toUpperCase();
        if (!units.includes(selectedUnit)) return 'Unit not supported';

        let i = units.indexOf(selectedUnit);
        let value = (bytes / Math.pow(1024, i)).toFixed(2);

        return `${value} ${selectedUnit}`;
    }
}
