import { Geojson } from "./geojson.model";

export namespace GeojsonAction {
    export class SetGeojson {
        static readonly type = '[Geojson] Set Geojson';
        constructor(public geojson: Geojson.Entity) {}
    }
    export class SetGeojsonCompliance {
        static readonly type = '[Geojson] Set Geojson Compliance';
        constructor(public geojson: Geojson.Entity) {}
    }
    export class SetGeojsonNoise {
        static readonly type = '[Geojson] Set Geojson Noise';
        constructor(public geojson: Geojson.Entity) {}
    }
    export class ClearGeojson {
        static readonly type = '[Geojson] Clear Geojson';
        constructor() {}
    }
    export class SetAllCompliance {
        static readonly type = '[Geojson] Set All Compliance';
        constructor(public geojsons: Geojson.Features[]) {}
    }
    export class SetAllNoise {
        static readonly type = '[Geojson] Set All Noise';
        constructor(public geojsons: Geojson.Features[]) {}
    }
    export class SetAll {
        static readonly type = '[Geojson] Set All';
        constructor(public geojsons: Geojson.Features[]) {}
    }
    export class UpsertAll {
        static readonly type = '[Geojson] Upsert All';
        constructor(public geojsons: Geojson.Features[]) {}
    }
    export class ClearAll {
        static readonly type = '[Geojson] Clear All';
        constructor() {}
    }
    export class Create {
        static readonly type = '[Geojson] Create';
        constructor(public geojson: Geojson.Features) {}
    }
    export class Update {
        static readonly type = '[Geojson] Update';
        constructor(public geojson: Geojson.Features) {}
    }
    export class Delete {
        static readonly type = '[Geojson] Delete';
        constructor(public geojson: Geojson.Features) {}
    }
}
