import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SplashscreenService {
  constructor() {
    this.splashScreen();
  }

  splashScreen() {
    let timeout = 1000;
    const splashScreen = document?.getElementById('splashscreen');
    if (splashScreen) {
      setTimeout(() => {
        splashScreen.style.display = 'none';
      }, timeout);
    }
  }
}
