import { Injectable } from '@angular/core';
import { State, StateContext, Selector, NgxsOnInit, NgxsSimpleChange, NgxsOnChanges, Action } from '@ngxs/store';
import { GeojsonAction } from './geojson.action';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Geojson } from './geojson.model';
import { clone } from '@shareds/utils/data.utils';

export interface GeojsonStateModel {
    geojsons: Geojson.Features[];
    geojsonsNoise: Geojson.Features[];
    geojsonsCompliance: Geojson.Features[];
    geojson: Geojson.Entity | null;
    geojsonNoise: Geojson.Entity | null;
    geojsonCompliance: Geojson.Entity | null;
    loaded: boolean;
}

@State<GeojsonStateModel>({
    name: 'geojson',
    defaults: {
        geojsons: [],
        geojsonsNoise: [],
        geojsonsCompliance: [],
        geojson: null,
        geojsonNoise: null,
        geojsonCompliance: null,
        loaded: false,
    },
})
@Injectable()
export class GeojsonState implements NgxsOnInit, NgxsOnChanges {
    @Selector()
    static getGeojsonsNoise(state: GeojsonStateModel) {
        return state.geojsonsNoise;
    }
    @Selector()
    static getGeojsonsCompliance(state: GeojsonStateModel) {
        return state.geojsonsCompliance;
    }
    @Selector()
    static getGeojsons(state: GeojsonStateModel) {
        return state.geojsons;
    }
    @Selector()
    static getGeojsonsNoiseData(state: GeojsonStateModel) {
        return state.geojsonNoise;
    }
    @Selector()
    static getGeojsonsComplianceData(state: GeojsonStateModel) {
        return state.geojsonCompliance;
    }
    @Selector()
    static getGeojsonsData(state: GeojsonStateModel) {
        return state.geojson;
    }

    @Selector()
    static hasLoaded(state: GeojsonStateModel) {
        return state.loaded;
    }

    constructor() {}

    ngxsOnInit(ctx: StateContext<GeojsonStateModel>): void {
        console.log('ngxsOnInit');
    }

    ngxsOnChanges(change: NgxsSimpleChange) {
        console.log('prev state', change?.previousValue);
        console.log('next state', change?.currentValue);
    }

    @Action(GeojsonAction.SetGeojson)
    async setGeojson({ getState, patchState }: StateContext<GeojsonStateModel>, { geojson }: GeojsonAction.SetGeojson) {
        const state = getState();
        patchState({
            geojson: geojson,
            loaded: true,
        });
    }
    @Action(GeojsonAction.SetGeojsonNoise)
    async setGeojsonNoise({ getState, patchState }: StateContext<GeojsonStateModel>, { geojson }: GeojsonAction.SetGeojsonNoise) {
        const state = getState();
        patchState({
            geojsonNoise: geojson,
            loaded: true,
        });
    }

    @Action(GeojsonAction.SetGeojsonCompliance)
    async setGeojsonCompliance({ getState, patchState }: StateContext<GeojsonStateModel>, { geojson }: GeojsonAction.SetGeojsonCompliance) {
        const state = getState();
        patchState({
            geojsonCompliance: geojson,
            loaded: true,
        });
    }


    @Action(GeojsonAction.ClearGeojson)
    async clearReport({ getState, patchState }: StateContext<GeojsonStateModel>, { }: GeojsonAction.ClearGeojson) {
        const state = getState();
        patchState({
            geojson: null,
            loaded: false,
        });
    }

    @Action(GeojsonAction.SetAll)
    async setAll({ getState, patchState }: StateContext<GeojsonStateModel>, { geojsons }: GeojsonAction.SetAll) {
        const state = getState();
        patchState({
            geojsons: geojsons,
            loaded: true,
        });
    }

    @Action(GeojsonAction.SetAllCompliance)
    async setAllCompliance({ getState, patchState }: StateContext<GeojsonStateModel>, { geojsons }: GeojsonAction.SetAllCompliance) {
        const state = getState();
        patchState({
            geojsonsCompliance: geojsons,
            loaded: true,
        });
    }

    @Action(GeojsonAction.SetAllNoise)
    async setAllNoise({ getState, patchState }: StateContext<GeojsonStateModel>, { geojsons }: GeojsonAction.SetAllNoise) {
        const state = getState();
        patchState({
            geojsonsNoise: geojsons,
            loaded: true,
        });
    }

    @Action(GeojsonAction.UpsertAll)
    async upsertAll({ getState, patchState }: StateContext<GeojsonStateModel>, { geojsons }: GeojsonAction.UpsertAll) {
        const state = getState();
        const itemExists = clone(state.geojsons) || [];
        geojsons?.forEach((i, index) => {
            const findIndex = itemExists.findIndex((e) => e.id === i.id);
            if (findIndex !== -1) {
                // itemExists[findIndex] = i;
            } else {
                itemExists.push(i);
            }
        });

        patchState({
            geojsons: itemExists,
            loaded: true,
        });
    }

    @Action(GeojsonAction.ClearAll)
    async clearAll({ getState, patchState }: StateContext<GeojsonStateModel>, { }: GeojsonAction.ClearAll) {
        const state = getState();
        patchState({
            geojsons: [],
            loaded: false,
        });
    }

    @Action(GeojsonAction.Create)
    async create({ getState, patchState, setState }: StateContext<GeojsonStateModel>, { geojson }: GeojsonAction.Create) {
        console.log("create", geojson);
        const state = getState();
        setState(
            patch({
                geojsons: insertItem(geojson),
                loaded: true,
            }),
        );
    }

    @Action(GeojsonAction.Update)
    async update({ getState, patchState, setState }: StateContext<GeojsonStateModel>, { geojson }: GeojsonAction.Update) {
        console.log("update", geojson);
        const state = getState();
        setState(
            patch({
                geojsons: updateItem(item => item.id === geojson.id, patch(geojson)),
                loaded: true,
            }),
        );
    }

    @Action(GeojsonAction.Delete)
    async delete({ getState, patchState, setState }: StateContext<GeojsonStateModel>, { geojson }: GeojsonAction.Delete) {
        console.log("delete", geojson);
        const state = getState();
        setState(
            patch({
                geojsons: removeItem<Geojson.Features>((item) => item.id === geojson.id),
                loaded: true,
            }),
        );
    }
}
